import glider from 'glider-js';

import { production } from '../utils';
import * as program from './program';

(function () {
    'use strict';

    autoPlayYouTubeModal();
    speakerSlider();

    gravityformHook();

    // Lazy load background images
    var lazyBackgrounds = [].slice.call(document.querySelectorAll('.lazy-background'));
    if ('IntersectionObserver' in window) {
        let lazyBackgroundObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                    lazyBackgroundObserver.unobserve(entry.target);
                }
            });
        });

        lazyBackgrounds.forEach(function (lazyBackground) {
            lazyBackgroundObserver.observe(lazyBackground);
        });
    }

    setTimeout(function () {
        loadSecret();
    }, 5000);
})();

function autoPlayYouTubeModal() {
    var triggerOpen = jQuery('body').find('[data-tagVideo]');
    triggerOpen.click(function () {
        var theModal = jQuery(this).data('bs-target'),
            videoSRC = jQuery(this).attr('data-tagVideo'),
            // videoSRCauto = videoSRC + "?autoplay=1";
            videoSRCauto = videoSRC;
        jQuery(theModal + ' iframe').attr('src', videoSRCauto);
        jQuery(theModal + ' button.btn-close').click(function () {
            jQuery(theModal + ' iframe').attr('src', videoSRC);
        });
    });
}

function speakerSlider() {
    if (jQuery('.glider').length > 0) {
        new Glider(document.querySelector('.glider'), {
            slidesToScroll: 1,
            // slidesToShow: 4.5,
            slidesToShow: 'auto',
            itemWidth: 173,
            draggable: false,
            scrollLock: true,
            rewind: true,
            exactWidth: true,
            // dots: '.dots',
            arrows: {
                prev: '#glider-prev',
                next: '#glider-next'
            },
            responsive: [
                {
                    // screens greater than >= 992px
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 'auto',
                        slidesToScroll: 1,
                        itemWidth: 247
                    }
                },
                {
                    // screens greater than >= 1200px
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 'auto',
                        slidesToScroll: 1,
                        itemWidth: 330
                    }
                }
            ]
        });
    }
}

function loadSecret() {
    // var script = document.createElement('script');
    // script.src = '/wp-content/themes/conference-2025/assets/dist/scripts/underscore.js';
    // script.async = true;
    // script.defer = true;
    // document.head.appendChild(script);
}

/*
Gravity Forms
*/
function gravityformHook() {
    if (jQuery('.blk_form .gform_wrapper form').length > 0) {
        jQuery('.blk_form .gform_wrapper form input,.blk_form .gform_wrapper form textarea').on(
            'focus',
            function (event) {
                jQuery(this).parents('.gfield').removeClass('invalid');
                jQuery(this).parents('.gfield').find('.field-message').remove();
            }
        );

        jQuery('.blk_form .gform_wrapper form').on('submit', function (event) {
            const gravityformURL = jQuery(this)
                .parents('.blk_form')
                .attr('data-gf-url')
                .replace('=', '');
            const gravityformID = jQuery(this).parents('.blk_form').attr('data-gf-id');
            const gravityformBaseURL = jQuery(this)
                .parents('.blk_form')
                .attr('data-gf-baseurl')
                .replace('=', '');

            // !production() && console.log('Endpoint:', gravityformURL);
            console.log('Endpoint:', gravityformURL);

            event.preventDefault();

            // Get all data
            const data = {};
            const values = {};
            jQuery.each(jQuery(this).serializeArray(), function (i, field) {
                if (field.name.startsWith('input_')) {
                    values[field.name] = field.value;
                } else if (field.name === 'g-recaptcha-response') {
                    values[field.name] = field.value;
                }
            });
            data.baseUrl = gravityformBaseURL;
            data.payload = values;

            !production() && console.log('Request:', { data });

            const json = JSON.stringify(data);

            var currentForm = jQuery(this);

            jQuery.ajax({
                method: 'POST',
                url: gravityformURL,
                data: json,
                contentType: 'application/json',
                beforeSend: function () {
                    // Show spinner
                    jQuery(currentForm).find('.gform_button').attr('disabled', 'disabled');
                    jQuery(currentForm).parents('.blk_form').addClass('loading');
                    jQuery(currentForm).parents('.blk_form').find('.form-messages').html('');
                },
                success: function (data) {
                    !production() && console.log('Response:', { data });

                    const result = data;

                    // Successful
                    if (result.status) {
                        if (result.confirmation_message) {
                            jQuery(currentForm)
                                .parents('.blk_form')
                                .find('.form-messages')
                                .html(result.confirmation_message);
                            jQuery(currentForm)[0].reset();
                            // jQuery(currentForm).find('.gform_button').removeAttr('disabled');
                            jQuery(currentForm).find('.gform_button').css('display', 'none');
                            jQuery(currentForm).parents('.blk_form').removeClass('loading');

                            setTimeout(function () {
                                jQuery(currentForm)
                                    .parents('.blk_form')
                                    .find('.form-messages')
                                    .html('');
                            }, 30000);
                            return;
                        }
                    }

                    // Errors
                    if (result.confirmation_message) {
                        jQuery.each(result.confirmation_message, function (i, e) {
                            !production() && console.log('Error:', { i, e });
                            const currentField = jQuery(currentForm).find(
                                '#field_' + gravityformID + '_' + i
                            );
                            jQuery(currentField).addClass('invalid');
                            jQuery(currentField).append(
                                '<div class="field-message">' + e + '</div>'
                            );
                        });

                        jQuery(currentForm).find('.gform_button').removeAttr('disabled');
                        jQuery(currentForm).parents('.blk_form').removeClass('loading');
                    }
                },
                error: function (xhr, status, error) {
                    !production() && console.log('Response:', { xhr, status, error });

                    jQuery(currentForm)
                        .parents('.blk_form')
                        .find('.form-messages')
                        .html(
                            '<div class="error-message">There was an error sending message. Please try again later.</div>'
                        );
                    jQuery(currentForm).find('.gform_button').removeAttr('disabled');
                    jQuery(currentForm).parents('.blk_form').removeClass('loading');

                    setTimeout(function () {
                        jQuery(currentForm).parents('.blk_form').find('.form-messages').html('');
                    }, 30000);
                }
            });
        });
    }
}
